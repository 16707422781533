<template>
  <div class="services">
    <div class="ser-our">
        <h3><span class="ser-our_first">our</span> Trainning</h3>
        <p>We offer web and software development services.</p>
    </div>

   <div class="container mt-5">
        <div class="card-deck cardmain">
         <div class="card cardmain-mb">
            <img src="../assets/programming-code.jpg" alt="" class="card-img-top img-fluid">
            <div class="card-img-overlay">
                <p class="text-left down">{{fullstack.head}}</p>
            </div>

            <div class="card-body text-dark">
                <ul class="course-ul" v-for="offer in fullstack.offers" :key="offer">
                    <li> <i class="fa fa-check-circle text-primary" aria-hidden="true"></i> {{offer}}</li>
                </ul>
            </div>

            <div class="card-footer">
                <router-link :to="{name:'Training', path:'/training'}" class="btn btn-primary bt-style position-relative stretched-link">
                    View Details &#8650;
                </router-link>
            </div>
        </div>
         <div class="card cardmain-mb">
            <img src="../assets/programming-code.jpg" alt="" class="card-img-top img-fluid">
            <div class="card-img-overlay"><p class="text-left down">{{python.head}}</p></div>
            <div class="card-body text-dark">
                <ul class="course-ul" v-for="offer in python.offers" :key="offer">
                    <li> <i class="fa fa-check-circle text-primary" aria-hidden="true"></i>{{offer}}</li>
                </ul>
            </div>
            <div class="card-footer">
                <router-link :to="{name:'Python', path:'/python'}" class="btn btn-primary bt-style position-relative stretched-link">
                View Details &#8650;
                </router-link>
            </div>
        </div>
         <div class="card cardmain-mb">
            <img src="../assets/fullstack.jpg" alt="" class="card-img-top img-fluid">
            <div class="card-img-overlay"><p class="text-left down">{{data.head}}</p></div>
            <div class="card-body text-dark">
                <ul class="course-ul" v-for="offer in data.offers" :key="offer">
                    <li><i class="fa fa-check-circle text-primary" aria-hidden="true"></i> {{offer}}</li>
                </ul>
            </div>
            <div class="card-footer">
                <router-link :to="{name:'DataScience', path:'/dataScience'}" class="btn btn-primary position-relative bt-style stretched-link">
                    View Details &#8650;
                </router-link>
            </div>
        </div>
    </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'Courses',
  data(){
      return{
          fullstack:{
              head:'FULL STACK WEB DEVELOPMENT',
              offers:['HTML Fundermentals','CSS and CSS Animation','Javascript','JQuery','Node js'],
          },
          python:{
              head:'PYTHON DEVELOPMENT',
              offers:['Fundermentals in Python','Python3 and Python2',' SCommand Line Basics','Python Lambda','Python Arrays'],
          },
          data:{
              head:'MASTER DATA SCIENCE',
              offers:['Research Methods','Visualizing Data','  Google Spreadsheet','Central Tendency','Variability'],
          }
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/app.scss';
    .course-ul{
        list-style: none;
        text-align: left;
        font-size: 2rem;
        line-height: 1.5;
    }
    .cardmain{
        @include respond(tab-port){
            display: flex;
            flex-direction: column !important;
        }
        &-mb{
            margin-bottom: 2rem !important;
        }
    }
    h3{
            font-size: 3rem;
            text-align: center;
        }
    .bt-style{
        font-size: 2rem;
        border-radius: .5rem;
        padding: 1rem 4rem;
    }

    .down{
        position:relative;
        background-color: rgba(137, 43, 226, 0.603);
        padding: 0 2rem;
        @include respond(tab-port){
            font-size: 3rem;
        }
        font-size: 3rem;
        line-height: 1.8;
    }
</style>
