<template>
  <div class="overview">
      
  <div class="secRow container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-6">
            <img src="../assets/branchnew.png" alt="img" height="450px" class="img-fluid mw">
        </div>

      <div  class="col-sm-12 col-md-6 ">
                  <div class="float">
              <ul class="dev_ul">
                <a href="#about">
                  <li class="dev_ul-li" @click="toggle='about'" :class="toggle=='about' ? 'mission' : ''">
                    {{overview.about.nav}}</li>
                </a>
                 <a href="#mission">
                   <li class="dev_ul-li" @click="toggle='mission'" :class="toggle=='mission' ? 'mission' : ''">
                     {{overview.mission.nav}}</li>
                  </a>
                 <a href="#vission">
                   <li class="dev_ul-li" @click="toggle='vission'" :class="toggle=='vission' ? 'mission' : ''">
                     {{overview.vision.nav}}</li>
                  </a>
                 <a href="#strategy">
                   <li class="dev_ul-li" @click="toggle='strategy'" :class="toggle=='strategy' ? 'mission' : ''">
                     {{overview.strategy.nav}}</li>
                  </a>
              </ul>
          </div>
        <div v-if="toggle=='mission'" id="we2">
          <h6 class="we-style" >{{head[0]}}</h6>
            <div class="P-style">
              <p>{{overview.mission.p1}}</p>

                <p class="mt-5">{{overview.mission.p2}}</p>
            </div>
          </div>

          <div v-else-if="toggle=='vission'" id="we2">
          <h6 class="we-style" >{{head[1]}}</h6>
            <div class="P-style">
              <p>{{overview.vision.p1}}</p>

                <p class="mt-5">{{overview.vision.p2}}</p>
.
            </div>
          </div>

          <div v-else-if="toggle=='strategy'" id="we2">
          <h6 class="we-style" >{{head[2]}}</h6>
            <div class="P-style">
                <p>{{overview.strategy.p1}}</p>

                <p class="mt-5">{{overview.strategy.p2}}</p>
            </div>
          </div>

          <div id="we1" v-else>
              <h6 class="we-style ">{{head[3]}}</h6>
                <div class="P-style">
                  <p>{{overview.about.p1}}</p>

                    <p class="mt-4">{{overview.about.p2}}</p>
                </div>
          </div>

  </div>
      </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Overview',
   data(){
      return{
          toggle:'about',
          head:['OUR MISSION','OUR VISION','COMPANY BUSINESS STRATEGY','WE ARE A CREATIVE WEB/SOFTWARE DEVELOPMENT COMPANY'],
          overview:{
            about:{
              p1:`
                    Oceandigits Technology Limited Services as a young growing
                    company has a compact and simple management structure with
                    energetic young minds on board of administration and
                    management. We are strongly determined to excel through
                    diligence and integrity. This is instrumental to our eﬀectiveness and
                    efciency which result in high productivity and quick delivery of
                    projects. We are committed to discipline of "Constant, Never-ending
                    Improvement".`,

              p2:`Our focus as an organization is to provide web, software and mobile
                    application development products and solutions to individuals and
                    organizations that need them`,
              nav:"about us"
            },

            mission:{
              p1:`
                Our company mission is to create value for our client by finding and
                developing thin-client solutions that are IT extensions of that client’s
                core business processes and marketing strategies.`,

              p2:`
                Oceandigits' business goal is to earn a signifcant position in the
                global Internet marketplace as a leader in both technical and
                creative advances in web-based business applications, particularly
                for the Application Development Ser
                achieved by continuing to develop and nurture the company’s
                human resources and strategic alli
                innovative and creative thinkers in the IT industry and even beyond
                the shores of the country.`,
                nav:"our mission"
            },

            vision:{
              p1:`
                Our Vision is to become a dynamic provider of Information and
                Communication Technology services to every organizations of
                interest and becoming a major player in the sector.`,

              p2:`
                At Oceandigits, we believe that the 21st century business world is
                technology driven, extremely competitive, complex and dynamic,
                therefore organizations need to consistently re-strategize their
                survival boundaries with creative and disruptive innovations. We are
                always on-hand to help, because we technically understand and
                speak the language of the business
                outstanding services to help our clients stand out.`,
                nav:"our vision"
            },

            strategy:{
              p1:` Oceandigits’ corporate strategy is to provide
                software solutions and data-centric business applications for the
                Internet while sharing the risks and rewards of intellectual property
                development with the client.`,

              p2:`
                  To drive a new era of development
                  communication technology in Nigeria for high productivity`,
              nav:"business strategy"
            }
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/app.scss";
.overview{
        line-height: 2rem;
        color: rgba(0, 0, 0, 0.856);
        padding: 5rem 0;
        background-image:
        radial-gradient(rgba(255, 255, 255, 0.904),rgba(255, 255, 255, 0.808)),
        url('../assets/workspace_SVG.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        h6{
          color: #292540;
        }
}
    .dev_ul{
        list-style: none;
        display: flex;
        margin-top: 2rem;
        margin-bottom:3rem;
        a{
          text-decoration: none;
        }
        @include respond(phone){
            margin-top: 3rem;
        }
        justify-content: space-between;
        list-style: none;
        font-weight: 700;
        &-li{
             display: block;
             text-transform: uppercase;
             font-size: 1.8rem;
             @include respond(phone){
            font-size: 1.2rem;
            }
             padding:5px;
             color: #292540ee;
             text-transform: capitalize;
        }
    }
    
.we-style{
        font-size: 2.4rem;
        line-height: 3.3rem;
        text-align: left;
        margin-bottom: 1.6rem;

        @include respond(phone){
          font-size: 2rem;
          margin-bottom: 1.1rem;
        }
    }

.secRow{
        padding-top: 50px;
        @include respond(phone){
            padding-top: 0;
        }
    }

    .mw{
      @include respond(phone){
        max-width: 80% !important;
        border-radius: 5px;
        margin-bottom: 2rem;
      }
    }

.P-style{
  font-size: 1.8rem;
  text-transform: none;
  backface-visibility: hidden;
  color: black;
};
ul {
  list-style-type: none;
  padding: 0;
  text-transform: bold;
};
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
  text-decoration: none;
}
.mission{
  border: 2px solid #292540;
  text-decoration: none;
  border-radius: 6px;
  background-color: #292540;
  color: white;
}
.float{
  text-align: center;
}
</style>
