<template>
  <div class="foot">
    <div class="container">
      <div class="row">
       <div class="col-sm-12 col-md-4">
           <h4 class="foot-header">Oceandigits</h4>
           <p class="foot-para">{{company}}</p>
       </div>
       <div class="col-sm-12 col-md-4">
           <h4 class="foot-header">What we offer</h4>
           <ul class="foot-ul">
               <li class="foot-li">
                      <vue-fontawesome icon="mobile" size="3" color="lightgray" class="pr-2"></vue-fontawesome>{{menu[0]}}</li>
               <li class="foot-li">
                  <vue-fontawesome icon="exclamation-circle" size="2" color="lightgray" class="pr-2"></vue-fontawesome>{{menu[1]}}</li>
               <li class="foot-li">
                  <vue-fontawesome icon="sitemap" size="2" color="lightgray" class="pr-2"></vue-fontawesome>{{menu[2]}}</li>
               <li class="foot-li">
                  <vue-fontawesome icon="group" size="2" color="lightgray" class="pr-2"></vue-fontawesome>{{menu[3]}}</li>
               <li class="foot-li">
                  <vue-fontawesome icon="street-view" size="2" color="lightgray" class="pr-2"></vue-fontawesome>{{menu[4]}}</li>
           </ul>
           <div>
               <p><vue-fontawesome icon="map-pin" size="2" color="blue" class="pr-2"></vue-fontawesome>{{address}}</p>
           </div>
       </div>
       <div class="col-sm-12 col-md-4">
           <h4 class="foot-header">Contact Us</h4>
           <form method="POST" data-netlify="true" data-netlify-honeypot="bot-field" name="Contac Us">
               <input type="hidden" name="form-name" value="Contac Us">
               <div class="d-none"><label>For Spam-bot, fill not: <input name="bot-field"></label></div>
               <div class="form-group"><input type="text" class="form-control big" name="name" placeholder="How Can We Address You"></div>
               <div class="form-group"><input type="email" class="form-control big" name="email" id="" placeholder="Your Email"></div>
               <div class="form-group"><textarea name="message" id="" class="form-control big" cols="28" rows="4" placeholder="Kindly Reach Out To Us"></textarea></div>
               <div class="form-group"><input type="submit" value="Reach Out" class="btn btn-primary bt-style"></div>
           </form>
       </div>
    </div>
        <div class="design">
            <p class="text-center">
                <i class="design-w">{{design}}</i>
            </p>
        </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data(){
      return{
          company:`
               Oceandigits Technology Ltd is a digital and innovation technology consulting 
               company with strong background in delivering tech and value driven solutions. 
               We are committed and passionate about creating innovative solutions that helps our 
               clients to continually drive their organization forward.`,

          menu:["Mobile App development",'Web App development','IT Strategy','Digital Solution','Business Branding'],
          address:'No 32, Adeniyi Jones, Ikeja, Lagos. Nigeria.',
          design:'designed by OceanDigits Dev Team.'
      }
  },
  components:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/app.scss';
    .foot{
        line-height: 40px;
        color: white;
        padding: 1.5rem 0;
        background-image:
        linear-gradient(to right,#292540f6,#292540ee);
        font-size: 2rem;
        opacity: .8rem;

        &-header{
            font-size: 3.4rem;
            line-height: 1.7;
            padding-bottom: 20px;
        }

        h4::first-letter{
            border-bottom: 5px solid #18CBCF;
        }

        &-para{
            line-height: 25px;
            text-align: justify;
            font-size: 1.7rem;
        }
        &-ul{
            list-style: none;
            text-align: left;
        }
        &-li{
            font-size: 18px;
            line-height: 25px;
            padding: 10px;
        }
        input,textarea{
            background-color: transparent;
            padding: 2.5rem;
            font-size: 2rem;
            color: white;
        }
        .bt-style{
        font-size: 2rem;
        border-radius: .5rem;
        padding: 1rem 4rem;
        }
        .design{
            margin-top: 1rem;
            border-top: 1px solid grey;
            &-w a{
                color: grey;
                font-size: 2rem;
                text-decoration: none;
            }
        }
    }
</style>
