<template>
  <div class="home">
    <Hero></Hero>
    <Overview></Overview>
      <section id="Services">
        <Services></Services>
      </section>
    <section id="OurOffer">
      <OurOffer></OurOffer>
    </section>
    <section id="Courses">
      <Courses ></Courses>
    </section>
    <section id="Footer">
      <Footer></Footer>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '../components/Hero';
import Overview from '../components/Overview';
import Footer from '../components/Footer';
import Services from '../components/Services'
import OurOffer from '../components/OurOffer'
import Courses from '../components/Courses'
export default {
  name: 'Home',
  components: {
    Hero,
    Overview,
    Footer,
    Services,
    OurOffer,
    Courses,
  }
}
</script>
<style lang="scss" scoped>
  
</style>
