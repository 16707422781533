<template>
  <div id="app">
        <!-- NAVIGATION -->
      <div id="nav">
         <nav class="navbar navbar-expand-md navbar-light fixed-top" style="color:white; background-color:#292540;">
      <div class="navbar-brand">
        <router-link to="/"><img src="./assets/logo1.svg" alt=""  height="45px" style="border-radius:6px;"></router-link>
      </div>
      <button class="navbar-toggler alert-primary" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse shadow" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto cw">
          <li class="nav-item active">
            <!-- <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a> -->
            <router-link class="nav-link sometin cw " to="/" exact> {{nav[0]}}</router-link>
          </li>

          <li class="nav-item">
            <!-- <a class="nav-link sometin" href="#">Disabled</a> -->
            <router-link class="nav-link sometin cw" :to="{name:'Services', hash:'#Services'}">
              {{nav[1]}}</router-link>
          </li>

          <li class="nav-item">
            <!-- <a class="nav-link sometin" href="#">Link</a> -->
            <router-link class="nav-link sometin cw" :to="{name:'OurOffer', hash:'#OurOffer'}">
              {{nav[2]}}</router-link>
          </li>
          
          <li class="nav-item">
            <!-- <a class="nav-link sometin" href="#">Disabled</a> -->
            <router-link class="nav-link sometin cw" :to="{name:'Courses', hash:'#Courses'}">
              {{nav[3]}}</router-link>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link sometin" href="#">Disabled</a> -->
            <router-link class="nav-link sometin cw" :to="{name:'Footer', hash:'#Footer'}">
              {{nav[4]}}</router-link>
          </li>
        </ul>
      </div>
</nav>
      </div>
    <router-view :key="$route.path"/> 
  </div>
</template>

<script>
export default {
  name: 'app',
  data(){
    return{
      nav:['home','services','projects','training','contact'],
    }
  }
  }
</script>

<style lang="scss">
@import 'src/assets/app.scss';
#app {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;
  line-height: 1.7;
  font-size: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.shadow{
  box-shadow:40px 40px 10px #42b983
}
.cw{
  color: #E7EAED !important;
  text-transform: uppercase;
  font-size: 14px;
}
.router-link-exact-active{
  border-bottom: 2px solid #18CBCF;
  text-decoration: none;
  border-radius: 10px;
  padding: 1rem;
  @include respond(phone){
    display: inline-block !important;
    padding: 1rem;
  }
  
}
</style>
