<template>
  <div class="services" id="services">
    <div class="container-fluid">
        <ProgressBar class="Lprogress"></ProgressBar>
        <div class="main">
            <div class="main-1">
                <div class="ser-top">
                    <h3>25+</h3>
                    <p>Projects</p>
                </div>
            </div>
            <div class="main-2">
                <div class="ser-top">
                    <h3>15+</h3>
                    <p>Clients</p>
                </div>
            </div>
            <div class="main-3">
                <div class="ser-top">
                    <h3>15</h3>
                    <p>Developers</p>
                </div>
            </div>
        </div>

            <div class="ser-our">
                <!-- <img src="../assets/stripe.jpg" alt=""> -->
                <h3><span class="ser-our_first">Our</span> Services</h3>
                <p>The services we offer the world as a state of the art web and software development outfit.</p>
            </div>

            <div class="ser-display container">
                <div class="ser-display-1">
                    <div class="ser-display-item"  @mouseover="toggle='show'" @mouseleave="toggle='null'">
                     <div class="ser-display-item-con">
                         <div v-if="toggle=='show'" class="lorem" >
                            <p>{{card.web}}</p>
                        </div>
                        <div v-else class="ser-display-item-main">
                            <p class="ser-display-item-i">
                                <i class="fa fa-code fa-5x" aria-hidden="true"></i>
                            </p>
                            <p class="ser-display-item-w">web app development</p>
                        </div>
                    </div>
                    </div>
                    <div class="ser-display-item" @mouseover="toggle='show2'" @mouseleave="toggle='null'">
                        <div class="ser-display-item-con">
                            <div v-if="toggle=='show2'" class="lorem" >
                                {{card.mobile}}
                            </div>
                            <div v-else class="ser-display-item-main">
                                <p class="ser-display-item-i">
                                    <i class="fa fa-mobile fa-5x" aria-hidden="true"></i>
                                </p>
                                <p class="ser-display-item-w">mobile app development</p>
                            </div>
                        </div>
                    </div>
                    <div class="ser-display-item">
                        <div class="ser-display-item-con" @mouseover="toggle='show3'" @mouseleave="toggle='null'">
                            <div v-if="toggle=='show3'" class="lorem">
                                {{card.UI}}
                            </div>
                            <div v-else class="ser-display-item-main">
                                <p class="ser-display-item-i">
                                    <i class="fa fa-apple fa-5x" aria-hidden="true"></i>
                                </p>
                                <p class="ser-display-item-w">ui & ux design</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ser-display-2">
                    <div class="ser-display-item" @mouseover="toggle='show4'" @mouseleave="toggle='null'">
                     <div class="ser-display-item-con">
                        <div v-if="toggle=='show4'" class="lorem" >
                            {{card.IT}}
                        </div>
                        <div v-else class="ser-display-item-main">
                            <p class="ser-display-item-i">
                                <i class="fa fa-laptop fa-5x" aria-hidden="true"></i>
                            </p>
                            <p class="ser-display-item-w">it strategy</p>
                        </div>
                </div>
                </div>
                <div class="ser-display-item">
                     <div class="ser-display-item-con" @mouseover="toggle='show5'" @mouseleave="toggle='null'">
                         <div v-if="toggle=='show5'" class="lorem">
                             {{card.solution}}
                         </div>
                        <div v-else class="ser-display-item-main">
                            <p class="ser-display-item-i">
                                    <i class="fa fa-line-chart fa-5x" aria-hidden="true"></i>
                                </p>
                                <p class="ser-display-item-w">digital solution</p>
                        </div>
                    </div>
                </div>
                <div class="ser-display-item">
                     <div class="ser-display-item-con" @mouseover="toggle='show6'" @mouseleave="toggle='null'">
                         <div v-if="toggle=='show6'" class="lorem">
                             {{card.branding}}
                         </div>
                        <div v-else class="ser-display-item-main">
                            <p class="ser-display-item-i">
                                <i class="fa fa-edit fa-5x" aria-hidden="true"></i>
                            </p>
                            <p class="ser-display-item-w">business branding</p>
                        </div>
                    </div>
                </div>
                </div>
                
            </div>
            
    </div>
  </div>
</template>

<script>
import ProgressBar from '../components/ProgressBar';
export default {
  name: 'Services',
  data(){
      return{
          
        toggle:null,
          card:{
              web:`
                With our website development, you do not have to adjust your speficications to the capabilities
                of a given platform or change your design visionsto fit a template- we deliver the work precisely
                to your requirement`,

                mobile:`Building  a custom website does not neccessarily means reinventing the wheel. We make
                use of agile methodology in our peojecta to fast track the development timeframe and while maintaining the
                highest quality of code`,

                IT:`All our websites are built to requirements and the expected user traffic is one of the most important
                ones. Your website will be able to stoically withstand the hordes of user and extend features.`,

                solution:`Whether you have a clear specification or just a vague idae of what the website should look 
                like, we work with you to analyze and document the website functionality.`,

                UI:`once we understand your needs it is time to get to the drafting board. We will be sharing our sketches
                and asking for your feedback with review.`,

                branding:`We help create a branding strategy by mapping out a social media strategy that aligns with the
                Company's brand identity by writting other digital assets that accurately reflect the brand voice.`
          }
      }
  },
  components:{
      ProgressBar
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../assets/app.scss';
.lorem{
    font-size: 1.5rem;
    text-align: left;
}
.services{
        line-height: 2rem;
        color: white;
        padding: 5rem 0;
        background-image:
        linear-gradient(to right,#292540f6,#292540ee),
        url('../assets/2798874.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .ser{

        &-top{
        text-transform: uppercase;
        color: #18CBCF;
        line-height:7rem ;
        @include respond(phone){
            line-height:5rem ;
        }
        h3{
            font-size: 3rem;
            text-align: center;
        }
        p{
            font-size: 1.7rem;
        }
        }

    &-our{
        padding: 1rem;
        @include respond(phone){
            padding:.6rem
        }
        background-image:linear-gradient(to right,#292540ee,#292540e1), 
        url('../assets/stripe1.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        box-shadow: 1rem 1rem #292540ee;
        border-radius: .5rem;
        h3{
            font-size: 4rem;
            @include respond(phone){
            font-size: 3rem;
            }
            color: #E7EAED;
            text-shadow: .7rem .3rem .4rem rgba(128, 128, 128, 0.822);
        }
        p{
            font-size: 2.5rem;
            @include respond(phone){
                font-size: 1.9rem;
            }
            opacity: .7;
        }
        &_first{
            color: #18CBCF;
            font-size: 5rem;
            @include respond(phone){
            font-size: 3rem;
            }
            text-transform: uppercase;
            text-shadow: .7rem .3rem .4rem grey;
        }
    }

    &-display{
        margin-top: 4rem;
        opacity: .9;

        &-1,&-2{
            display: flex;
            flex: 0 1 30%;
            @include respond(tab-port){
                flex-direction: column;
            }
        }

        &-item{
            flex:1 0 30%;
            background-color: #595959;
            padding:3rem;
            border-radius: .5rem;
            border: 0.5px solid #C4C4C4;
            box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.726);
            margin: 1rem;

            &-main{
                display: flex;
            }
            
            &-w{
                font-size: 2rem;
                @include respond(phone){
                    font-size: 1.4rem;
                }
                text-transform: uppercase;
                text-align: center;
                padding: 1rem;
                margin:auto;
            }
            &-i{
                font-size: 2rem;
                @include respond(phone){
                    font-size: 1.4rem;
                }
                padding: 1rem;
            }
        }
      }
    }
    .Lprogress{
        @include respond(phone){
            display: none;
        }
    }
    .main{
        @include respond(phone){
            display: flex;
            justify-content: space-between;
        }
        display: none;
    }
</style>
