<template>
  <div class="pt-5 container-fluid hero">
    <div class="container-fluid pt-5">
        <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-6 text-white">
                <h4 class="mb-4 head pt-5 mt-3">{{head[0]}}</h4>
                <h2 class="h-style">Oceandigits</h2>
                <h6 class="we-style">{{head[2]}}</h6>
                <p class="P-style">{{company}}</p>
                <router-link class="sometin cw mt-3 b-style d-block m-auto" :to="{name:'Courses', hash:'#Courses'}">
                    learn more &raquo;
                </router-link>
            </div>
            <div class="col-sm-12 col-md-6 text-white">
                <h4 class="head pt-5 mt-3">{{head[1]}}</h4>
                <div id="carouselId " class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators text-primary">
                        <li data-target="#carouselId" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselId" data-slide-to="1"></li>
                        <li data-target="#carouselId" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner" role="listbox">
                        <div class="carousel-item active">
                            <img src="../assets/Carol1.png" alt="First slide" class="img-fluid">
                            <div class="carousel-caption d-none d-md-block">
                                <!-- <h3>Title</h3>
                                <p>Description</p> -->
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="../assets/Carol2.png" alt="Second slide" class="img-fluid">
                            <div class="carousel-caption d-none d-md-block">
                                <!-- <h3>Title</h3>
                                <p>Description</p> -->
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="../assets/Carol3.png" alt="Third slide" class="img-fluid">
                            <div class="carousel-caption d-none d-md-block">
                                <!-- <h3>Title</h3>
                                <p>Description</p> -->
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselId" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselId" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
     </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  data(){
      return{
          head:['REVOLUTIONIZE THE WORLD','LEARN TO CODE','WE ARE A CREATIVE WEB/SOFTWARE DEVELOPMENT COMPANY'],
          company:`
              We employ the best innovative Internet technologies to deliver complete enterprise 
              internet & intranet solutions, consulting, custom programming, IS services, 
              web-based business applications and integration services.
          `
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/app.scss";
.hero{
    padding: 5rem 0;
    background-image:
    linear-gradient(to right,#292540f6,#292540ee),url('../assets/2798874.jpg');
    background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
}
.head{
    font-size: 3rem;
    color: #18CBCF;
    @include respond(phone){
        margin-top: 2rem;
    }
}
    .P-style{
        text-align: left;
        font-size: 1.8rem;
        font-weight: 200;
        line-height: 2.7rem;
        margin-bottom: 16px;
        //text-transform: uppercase;
    }
    .h-style{
        font-weight: 600;
        font-size: 7.2rem;
        @include respond(phone){
            font-size: 4.2rem;
        }
        line-height: 98px;
        margin-bottom: 16px;
        text-align: left;
    }
    .we-style{
        font-size: 2.4rem;
        line-height: 3.3rem;
        text-align: left;
        margin-bottom: 1.6rem;
    }
    .b-style{
        font-size: 2rem;
        padding: 1.5rem 4rem;
        @include respond(phone){
            padding: 1.5rem 2rem;
        }
        float: left;
        letter-spacing: .2rem;
        border-radius: 1.3rem;
        background-color: #18CBCF;
        border: none;
    }
    .img-style{
        border-radius: 10px;
        box-shadow: 20px 40px 50px rgba(0, 0, 0, 0.404)
    }
    
</style>
