import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[
    {
      path: '/',
      component: Home,
      props:true,

      children:[
        {
          path: '',
          name: 'Services',
          component: ()=>import( "../components/Services"),
        },
        {
          path: '',
          name: 'Courses',
          component: ()=>import( "../components/Courses"),

        },
        {
          path: '',
          name: 'OurOffer',
          component: ()=>import( "../components/OurOffer"),
        },
        {
          path: '',
          name: 'Footer',
          component: ()=>import( "../components/Footer"),
        },
      ]
    },
    {
      path: '/training',
      name: 'Training',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Fullstack.vue')
    },
    {
      path: '/datascience',
      name: 'DataScience',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/DataScience.vue')
    },
    {
      path: '/python',
      name: 'Python',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Python.vue')
    },
  ],

  scrollBehavior(to, from, savedPosition){
    if (savedPosition) {
      return savedPosition;
    } else {
      const position={};
      
      if(to.hash){
        position.selector=to.hash;
        position.offset=({y:60});
        if(document.querySelector(to.hash)){
          return position;
        }
        return false;
      }else{
        return {y:0,x:0};
      }
    }
  },
});
export default router;
