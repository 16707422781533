<template>
  <div class="ouroffer">
    <div class="container-fluid">
      <div class="ser-our">
                <!-- <img src="../assets/stripe.jpg" alt=""> -->
                <h3><span class="ser-our_first">why</span> Everyone choose us</h3>
                <p>What we bring to the table.</p>
        </div>
        <div class="container">
            <div class="row offer">
                <div class="col-md-6 col-12 m-auto">
                    <h3 class="offer-p">CLEAN <span>CODE</span></h3>
                    <h2 class="offer-h4">We write our codes and build our projects with <span>efficiency</span> with respect to time.</h2>
                    <div class=""><button class="btn btn-primary btn-lg p-4 shadow mb-5" style="font-size:20px;">Our Coding Practice</button></div>
                </div>

                <div class="col-md-5 col-12">
                    <img src="../assets/workspace_SVG.svg" alt="" class="img-fluid mw">
                </div>
        </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="ser-our">
                <!-- <img src="../assets/stripe.jpg" alt=""> -->
                <h3><span class="ser-our_first">some</span> of our past project</h3>
                <p>A glimpse of what we will deliver.</p>
        </div>
        
        <div class="container">
            <div class="row mt-5">
            <div class="col-sm-12">
                <h1 class="">INVESTA</h1>
                <div id="carouselId" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators d-none">
                        <li data-target="#carouselId" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselId" data-slide-to="1"></li>
                        <li data-target="#carouselId" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner" role="listbox">
                        <div class="carousel-item active">
                            <img src="../assets/investa01.png" alt="First slide" width="1600px" class="img-fluid" height="400px">
                            <div class="carousel-caption d-none d-md-block">
                                <h3>Title</h3>
                                <p>Description</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="../assets/investa11.png" alt="Second slide" width="1600px" class="img-fluid" height="400px">
                            <div class="carousel-caption d-none d-md-block">
                                <h3>Title</h3>
                                <p>Description</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="../assets/investa21.png" alt="Third slide" width="1600px" class="img-fluid" height="400px">
                            <div class="carousel-caption d-none d-md-block">
                                <h3>Title</h3>
                                <p>Description</p>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselId" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselId" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
        </div>

        <div class="row mt-5">
                    <div class="col-12 col-sm-4">
                        <div class="card  bg-secondary text-white mb-5">
                        <div><img src="../assets/ocean 1.svg" alt="" class="card-img-top"></div>
                        <div class="card-body">
                            <h2 class="card-title">Oceans Digits</h2>
                            <p></p>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="card bg-dark text-white mb-5">
                        <div class="">
                            <img src="../assets/niyod 1.png" alt="" height="200px" class="card-img-top">
                        </div>
                        <div class="card-body">
                            <h2 class="card-title">NIYOD</h2>
                            <p></p>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="card  bg-secondary text-white mb-5">
                        <img src="../assets/amote 1.svg" alt="" class="card-img-top">
                        <div class="card-body">
                            <h2 class="card-title">AMOTE</h2>
                            <p></p>
                        </div>
                    </div>
                    </div>
            </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ouroffer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/app.scss';
    .ouroffer{
        line-height: 2rem;
        color: rgba(0, 0, 0, 0.856);
        padding: 5rem 0;
        background-image:
        radial-gradient(rgba(255, 255, 255, 0.904),rgba(255, 255, 255, 0.808)),
        url('../assets/workspace_SVG.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .project{
        display: flex;
        justify-content: space-between;
    }
    .shadow{
        box-shadow: 1rem 1rem 2rem #292540ee;
    }
    .offer{
        padding:5rem 0;
        &-p{
            font-size:3.5rem ;
            span{
                color: white;
                padding: .5rem;
                background-color: #18CBCF;
                border-radius: .5rem;
            }
        }
        &-h4{
            padding: 1.5rem;
            font-size: 3rem;
            line-height: 4rem;
            text-align: left;
            @include respond(phone){
                font-size: 2.5rem;
            }
            span{
                color: #F07B81;
                font-size: 3rem;
            }
        }
    }

    .mw{
      @include respond(tab-port){
        max-width: 80% !important;
        border-radius: 5px;
      }
    }
    .ser-our{
        padding: 1rem;
        @include respond(phone){
            padding:.6rem
        }
        background-image:linear-gradient(to right,white,white), 
        url('../assets/stripe1.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        box-shadow: .5rem .5rem 2rem #292540ee;
        border-radius: .5rem;
        h3{
            font-size: 4rem;
            @include respond(phone){
                font-size: 2rem;
            }
            color: black;
            text-transform: uppercase;
            text-shadow: .7rem .3rem .4rem rgba(128, 128, 128, 0.822);
        }
        p{
            font-size: 2.5rem;
            opacity: .7;
            @include respond(phone){
                font-size: 2.2rem;
            }
        }
    }
</style>
