Vue.config.productionTip = false;
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import './assets/app.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import "font-awesome/css/font-awesome.min.css";
import jQuery from 'jquery';

window.$=window.jQuery=jQuery;//with this you will have access to use $ anywhere

library.add(faUserSecret);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
